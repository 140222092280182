import {
  AsyncPipe,
  DatePipe,
  NgClass,
  NgIf,
  NgOptimizedImage,
  PercentPipe
} from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { IUserDetails, Referral } from '@buildshare/common';
import { Share } from '@capacitor/share';
import {
  IonBackButton,
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSkeletonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  LoadingController
} from '@ionic/angular/standalone';
import { NgLetModule } from 'ng-let';
import {
  Observable,
  Subscription,
  filter,
  firstValueFrom,
  mergeMap,
  tap
} from 'rxjs';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { ReferralService } from 'src/app/core/services/referral/referral.service';
import { ToastService } from 'src/app/core/services/toast/toast.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { AvatarComponent } from '../core/components/avatar/avatar.component';
import { StorageService } from '../core/services/storage/storage.service';

enum Tab {
  Invite = 'invite',
  Referrals = 'referrals'
}

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    NgClass,
    DatePipe,
    PercentPipe,
    NgLetModule,
    AvatarComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonSkeletonText,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonContent,
    IonItem,
    IonCard,
    IonThumbnail,
    IonBadge,
    IonCardContent,
    IonCheckbox,
    IonButton,
    IonIcon,
    IonList,
    IonFooter,
    NgOptimizedImage,
    RouterLink
  ],
  providers: [PercentPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReferComponent implements OnInit, OnDestroy {
  myReferrals$: Observable<Referral[]>;
  activeReferralsCount$: Observable<number>;
  bonusRate$: Observable<number>;
  myTotalBonusRate$: Observable<number>;
  user$: Observable<IUserDetails>;
  referredByUser$: Observable<IUserDetails>;
  userReferralBonusActive$: Observable<boolean>;
  tab: Tab;
  segmentSub: Subscription;

  constructor(
    private user: UserService,
    private referrals: ReferralService,
    private analytics: AnalyticsService,
    private loading: LoadingController,
    private toast: ToastService,
    private router: Router,
    private percentPipe: PercentPipe,
    private meta: Meta,
    public storage: StorageService
  ) {}

  ngOnInit() {
    this.meta.updateTag({
      name: 'description',
      content: 'Refer friends to Buildshare and earn bonus interest.'
    });
    this.user$ = this.user.details;
    this.myReferrals$ = this.referrals.myReferrals.pipe(
      // Add referee's name to each referral
      mergeMap(async (referrals) => {
        for (const referral of referrals) {
          const referree = await this.user.getUserDetails(
            referral.referredUserId
          );
          referral.referredUserFirstName = referree.firstName;
          referral.referredUserSurname = referree.surname;
        }
        return referrals;
      })
    );
    this.referredByUser$ = this.user.details.pipe(
      mergeMap((user) => {
        if (user.referredBy) {
          return this.user.getUserDetails(user.referredBy);
        } else {
          return undefined;
        }
      })
    );
    this.bonusRate$ = this.referrals.referralBonusRate.pipe(
      tap(async (rate) => {
        if (!rate) {
          await this.router.navigate(['/']);
          await this.toast.present(
            'The referral scheme is currently disabled. Check back soon.'
          );
        }
      })
    );
    this.userReferralBonusActive$ = this.referrals.userReferralBonusActive;
    this.activeReferralsCount$ = this.referrals.myActiveReferralCount;
    this.myTotalBonusRate$ = this.referrals.myCurrentBonusRate;

    // Update segment with child route (which tab is selected)
    this.tab = this.router.url.split('/').pop() as Tab;
    this.segmentSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.tab = event.urlAfterRedirects.split('/').pop() as Tab;
      });
  }

  async changeTab(ev) {
    const tab = ev.detail.value as Tab;
    await this.router.navigate(['refer', tab]);
  }

  async shareReferralLink() {
    const loading = await this.loading.create({
      message: 'Generating link...'
    });
    await loading.present();
    let referralLink;
    try {
      // Generate via function and store in database
      referralLink = await this.referrals.generateReferral();
    } catch (error) {
      await this.analytics.handleExpectedErrors(
        'Failed to generate referral link',
        error,
        {},
        'There was a problem generating a referral link. Please try again.'
      );
      return;
    } finally {
      await loading.dismiss();
    }

    const sharingSupported = (await Share.canShare()).value;
    const title = `Join me on Buildshare and profit from housing developments`;
    const rate = await firstValueFrom(this.bonusRate$);
    const ratePercent = this.percentPipe.transform(rate, '1.0-2');

    if (sharingSupported) {
      try {
        await Share.share({
          title: title,
          text: `Join & make your first investment within 30 days and we'll both receive a bonus ${ratePercent} interest boost for 12 months.`,
          url: referralLink,
          dialogTitle: title
        });
      } catch (error) {
        if (
          ['Share canceled', 'Abort due to cancellation of share.'].includes(
            error.message
          )
        ) {
          return;
        }
        await this.analytics.handleExpectedErrors(
          'Failed to open share dialogue for referral link',
          error,
          {},
          'There was a problem sharing your referral link. Please try again.'
        );
      }
    } else {
      navigator.clipboard.writeText(referralLink);
      await this.toast.present('Referral link copied to clipboard');
    }
  }

  async remind() {
    const sharingSupported = (await Share.canShare()).value;
    const title = `Reminder: your Buildshare referral hasn't been activated yet.`;
    const rate = await firstValueFrom(this.bonusRate$);
    const ratePercent = this.percentPipe.transform(rate, '1.0-2');

    if (sharingSupported) {
      try {
        await Share.share({
          title: title,
          text:
            `We'll both receive a bonus ${ratePercent} interest on our investments for 12 months as soon as you've invested.` +
            ' Check out these projects to get started.',
          url: 'https://buildshare.app/projects',
          dialogTitle: title
        });
      } catch (error) {
        if (error.message === 'Share canceled') return;
        await this.analytics.handleExpectedErrors(
          'Failed to open share dialogue for reminding referee',
          error,
          {},
          'There was a problem sending the reminder. Please try again.'
        );
      }
    } else {
      await this.toast.present(
        'Looks like sharing is not supported in your browser.'
      );
    }
  }

  ngOnDestroy() {
    this.segmentSub.unsubscribe();
  }
}
