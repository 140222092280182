<ion-header translucent>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button
        defaultHref="/tabs/me"
        class="icon-back-button"
        text=""
      ></ion-back-button>
    </ion-buttons>
    <ion-title>Referrals</ion-title>
  </ion-toolbar>

  <ion-toolbar id="stats">
    <ion-grid>
      <ion-row class="ion-justify-content-around">
        <ion-col size="5">
          <div class="badge-stat">
            <img
              ngSrc="assets/core/icons/rocket.svg"
              alt="Rocket graphic"
              width="40"
              height="40"
              [loading]="storage.assetLoadingMode"
            />
            <h1 *ngLet="myTotalBonusRate$ | async as totalBonusRate">
              @if (totalBonusRate !== undefined) {
                {{ totalBonusRate | percent: '1.0-2' }}
              } @else {
                <ion-skeleton-text
                  animated
                  style="width: 50px; height: 30px"
                ></ion-skeleton-text>
              }
            </h1>
          </div>
          <small>My rate boost</small>
        </ion-col>
        <ion-col size="5">
          <div class="badge-stat">
            <img
              ngSrc="assets/core/icons/handshake.svg"
              alt="Handshake graphic"
              width="40"
              height="40"
              [loading]="storage.assetLoadingMode"
            />
            <h1 *ngLet="activeReferralsCount$ | async as activeReferralsCount">
              @if (activeReferralsCount !== undefined) {
                {{ activeReferralsCount }}
              } @else {
                <ion-skeleton-text
                  animated
                  style="width: 50px; height: 30px"
                ></ion-skeleton-text>
              }
            </h1>
          </div>
          <small>Active referrals</small>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>

  <ion-toolbar>
    <ion-segment [value]="tab" (ionChange)="changeTab($event)">
      <ion-segment-button value="invite">
        <ion-label>Invite</ion-label>
      </ion-segment-button>
      <ion-segment-button value="referrals">
        <ion-label>My Referrals</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<!-- CONTENT ---------------------------------------------------->
<ion-content fullscreen>
  @switch (tab) {
    <!-- Invite tab -->
    @case ('invite') {
      <div id="overview" class="ion-padding">
        <div id="graphic">
          <img
            ngSrc="assets/core/graphics/refer.jpg"
            alt="Referrals graphic"
            width="200"
            height="200"
            priority
          />
        </div>
        <h2>Refer and earn</h2>
        <p class="ion-padding">
          Bring friends to help you build, & for each that invests, you'll both
          get a <b>+{{ bonusRate$ | async | percent: '1.0-2' }}</b> bonus rate
          on your investments for 12 months!
        </p>
      </div>
      <div id="how-it-works">
        <ion-item class="ion-margin-top" lines="none">
          <h3>How it works</h3>
        </ion-item>
        <swiper-container
          initial-slide="0"
          slides-per-view="auto"
          space-between="0"
          class="responsive-swipe-cards"
        >
          <swiper-slide>
            <ion-card>
              <ion-item lines="none" color="none">
                <ion-thumbnail slot="start">
                  <img
                    ngSrc="assets/core/icons/link.svg"
                    alt="Web link icon"
                    width="40"
                    height="40"
                    [loading]="storage.assetLoadingMode"
                  />
                </ion-thumbnail>
                <ion-label
                  class="ion-text-wrap ion-padding-top ion-padding-bottom"
                >
                  <h2>
                    <b>Share a link</b>
                  </h2>
                  Your friend will be directed to install the app then sign up
                  or log in with an existing account.
                </ion-label>
              </ion-item>
            </ion-card>
          </swiper-slide>
          <swiper-slide>
            <ion-card>
              <ion-item lines="none" color="none">
                <ion-thumbnail slot="start">
                  <img
                    ngSrc="assets/core/icons/notification.svg"
                    alt="Notification bell icon"
                    width="40"
                    height="40"
                    [loading]="storage.assetLoadingMode"
                  />
                </ion-thumbnail>
                <ion-label
                  class="ion-text-wrap ion-padding-top ion-padding-bottom"
                >
                  <h2><b>They invest</b></h2>
                  Once they invest in their first project, you'll get a
                  notification that the referral was successful.
                </ion-label>
              </ion-item>
            </ion-card>
          </swiper-slide>
          <swiper-slide>
            <ion-card>
              <ion-item lines="none" color="none">
                <ion-thumbnail slot="start">
                  <img
                    ngSrc="assets/core/icons/money.svg"
                    alt="Stack of money icon"
                    width="40"
                    height="40"
                    [loading]="storage.assetLoadingMode"
                  />
                </ion-thumbnail>
                <ion-label
                  class="ion-text-wrap ion-padding-top ion-padding-bottom"
                >
                  <h2><b>Get your bonus</b></h2>
                  You'll both see your interest rates boost by the
                  <b>{{ bonusRate$ | async | percent: '1.0-2' }}</b> bonus in
                  your projects for 12 months!
                </ion-label>
              </ion-item>
            </ion-card>
          </swiper-slide>
        </swiper-container>
      </div>
      <div id="terms" class="ion-text-center ion-margin ion-padding">
        View referral <a routerLink="/referral-terms">terms and conditions</a>
      </div>
    }
    <!-- Referrals tab -->
    @case ('referrals') {
      <!-- User referred by another -->
      @if (user$ | async; as user) {
        @if (user.referredBy) {
          <ion-item id="my-referral" lines="full">
            <app-avatar slot="start" [userId]="user.referredBy"></app-avatar>
            <ion-label>
              @if (referredByUser$ | async; as referredByUser) {
                <h2>
                  {{ referredByUser.firstName }} {{ referredByUser.surname }}
                </h2>
              } @else {
                <h2>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </h2>
              }
              <p>Referred you on {{ user.referredDate | date }}</p>
            </ion-label>
            <!-- Show interest boost badge if still active -->
            @if (userReferralBonusActive$ | async) {
              <ion-badge slot="end" color="success"
                >+{{ bonusRate$ | async | percent: '1.0-2' }}</ion-badge
              >
            } @else {
              <ion-badge slot="end" color="light">Expired</ion-badge>
            }
          </ion-item>
        }
      }
      <!-- List of sent referrals -->
      @if (myReferrals$ | async; as myReferrals) {
        @if (myReferrals.length && myReferrals.length > 0) {
          @for (referral of myReferrals; track referral) {
            <ion-card
              class="referree-card"
              [ngClass]="referral.expired && 'expired'"
            >
              <ion-card-content>
                <!-- Referree details -->
                <ion-item>
                  <app-avatar
                    slot="start"
                    [userId]="referral.referredUserId"
                  ></app-avatar>
                  <ion-label>
                    <h2>
                      {{ referral.referredUserFirstName }}
                      {{ referral.referredUserSurname }}
                    </h2>
                    @if (!referral.active && !referral.expired) {
                      <p>Redeemed on {{ referral.redemptionDate | date }}</p>
                    }
                    @if (referral.active) {
                      <p>Expires on {{ referral.expiryDate | date }}</p>
                    }
                    @if (referral.expired) {
                      <p>Expired on {{ referral.expiryDate | date }}</p>
                    }
                  </ion-label>
                </ion-item>
                <!-- Referall progress -->
                @if (!referral.expired) {
                  <ion-grid>
                    <ion-row class="ion-justify-content-around ion-text-center">
                      <ion-col size="5">
                        <ion-checkbox
                          color="success"
                          labelPlacement="end"
                          checked="true"
                          disabled
                          >Signed up</ion-checkbox
                        >
                      </ion-col>
                      <ion-col size="5">
                        <ion-checkbox
                          color="success"
                          labelPlacement="end"
                          [checked]="referral.invested"
                          disabled
                          >Invested</ion-checkbox
                        >
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                }
                <!-- Status Button -->
                @if (!referral.active && !referral.expired) {
                  <ion-button expand="block" class="solid" (click)="remind()"
                    >Remind {{ referral.referredUserFirstName }}</ion-button
                  >
                }
                @if (referral.active) {
                  <ion-button
                    expand="block"
                    fill="outline"
                    color="success"
                    disabled
                  >
                    <ion-icon
                      slot="start"
                      src="assets/core/icons/rocket.svg"
                    ></ion-icon>
                    <b
                      >{{ bonusRate$ | async | percent: '1.0-2' }} boost
                      active</b
                    >
                  </ion-button>
                }
                @if (referral.expired) {
                  <ion-button
                    class="ion-margin-top solid"
                    expand="block"
                    fill="outline"
                    disabled
                  >
                    Expired
                  </ion-button>
                }
              </ion-card-content>
            </ion-card>
          }
        } @else {
          <div id="overview" class="ion-padding">
            <div id="graphic" class="ion-padding">
              <img
                class="ion-padding"
                ngSrc="assets/core/icons/paper-plane.svg"
                alt="Paper plane icon"
                width="200"
                height="200"
                priority
              />
            </div>
            <h3>No redeemed referrals yet</h3>
            <p class="ion-padding">
              After redeeming your referral link, your friends will appear here
              along with the status of their referrals.
            </p>
          </div>
        }
      } @else {
        <ion-list>
          @for (i of [0, 1, 2]; track i) {
            <div class="ion-margin">
              <ion-skeleton-text
                animated
                style="height: 210px; border-radius: 20px"
              ></ion-skeleton-text>
            </div>
          }
        </ion-list>
      }
    }
  }
</ion-content>

<ion-footer translucent>
  <ion-toolbar>
    <ion-button class="ion-margin" expand="block" (click)="shareReferralLink()">
      Share referral link
    </ion-button>
  </ion-toolbar>
</ion-footer>
